footer {
  background: #F5F3E4;
  width: 100vw;
  margin-top: calc(3vw + 3rem);
  padding: calc(1vw + 1rem) calc(1vw + 1rem) calc(.5vw + .5rem) calc(1vw + 1rem);
}

footer h2 {
  font-size: calc(.5vw + .5rem);
  line-height: calc(1vw + 1rem);
}

footer .home-link:hover {
  text-decoration: none;
}

footer .footer-link {
  color: #641924;
  font-size: calc(.5vw + .5rem);
}

footer img {
  width: calc(1vw + 1rem);
  margin-left: 2vw;
}

@media screen and (max-width: 767px) {
  footer .col-md-4 {
    display: none !important;
  }

  footer img {
    margin-left: 0;
  }
}


@media screen and (max-width: 400px) {
  footer .row {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
  }

}