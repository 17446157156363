.curriculum-card {
  padding: calc(1vw + 1rem);
  background: #FDFBEC;
  border-top: .75vw solid #A10D1F;
  border-radius: .75vw .75vw 0 0;
  display: flex;
  width: 25vw;
  align-items: flex-start;
  margin: calc(1vw + 1rem) 0;
}

.curriculum-card img {
  width: 2vw;
  margin-right: 1vw;
}

.curriculum-card h1 {
  font-size: 1.25vw;
  line-height: 2vw;
}

.curriculum-card p {
  font-size: 1vw;
  line-height: 2vw;
}

@media screen and (max-width: 992px) {
  .curriculum-card {
    width: 40vw;
  }
}

@media screen and (max-width: 767px) {
  .curriculum-card {
    width: 90vw;
    height: fit-content;
  }

  .curriculum-card img {
    width: 4vw;
    margin-right: 2vw;
  }

  .curriculum-card h1 {
    margin-left: calc(.5vw + .5rem);
    text-align: left;
    font-size: calc(.75vw + .75rem);
  }

  .curriculum-card p {
    text-align: left;
    font-size: calc(.5vw + .5rem);
    line-height: calc(1.25vw + 1.25rem);
  }
}