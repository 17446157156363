@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html, body {
  width: 100% !important;
  overflow-x: hidden !important;
  background: #FDFBEC;
}

.custom-control-input:focus~.custom-control-label::before {
  border-color: #AD4857 !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #AD4857 !important;
  background-color: #AD4857 !important;
}

.custom-control-input:active~.custom-control-label::before {
  background-color: #AD4857 !important;
  border-color: #AD4857 !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #AD4857 !important;
}

body section button.btn:focus {
  box-shadow: none;
}
.navbar {
  width: 100vw;
  z-index: 999;
  background: #fdfbec;
  transition: box-shadow .25s;
  padding: calc(.25vw + .25rem) calc(2.5vw + 2.5rem);
}

.navbar-brand img {
  width: calc(8vw + 8rem);
}

.navbar-nav .nav-link {
  padding: 0 !important;
  color: #641924 !important;
  font-weight: 400;
  font-size: calc(.5vw + .5rem);
  margin: 0 calc(.5vw + .5rem);
}

.navbar-nav .nav-link.active {
  font-weight: bold !important;
}

.burger {
  display: none;
}

/* tablet mode starts at 992px */

@media screen and (max-width: 992px) {
  .navbar-nav {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    -webkit-clip-path: ellipse(0px 0px at 95% 5%);
            clip-path: ellipse(0px 0px at 95% 5%);
    background: #F4897B;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 2;
    transition: -webkit-clip-path 1s ease-in-out;
    transition: clip-path 1s ease-in-out;
    transition: clip-path 1s ease-in-out, -webkit-clip-path 1s ease-in-out;
  }
  .navbar-nav .nav-link {
    font-size: calc(1vw + 1rem) !important;
    text-align: center;
    margin-top: 5vh;
    margin-left: 0 !important;
  }
  .navbar-nav.active {
    -webkit-clip-path: ellipse(150% 150% at 100% 0%);
            clip-path: ellipse(150% 150% at 100% 0%);
  }
  .navbar-brand {
    z-index: 999;
  }
  .burger {
    z-index: 999;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
    display: block;
  }
  .burger:focus {
    border: none;
    outline: none;
  }
  .burger div {
    background: #641924;
    border-radius: 4px;
    width: 30px;
    height: 3px;
    margin-bottom: 4px;
    transition: .5s ease-in-out;
  }
  .navbar-nav.active~.burger div:first-child {
    -webkit-transform: rotateZ(45deg) translateY(200%);
            transform: rotateZ(45deg) translateY(200%);
  }
  .navbar-nav.active~.burger div:nth-child(2) {
    opacity: 0;
  }
  .navbar-nav.active~.burger div:last-child {
    -webkit-transform: rotateZ(-45deg) translateY(-300%) translateX(15%);
            transform: rotateZ(-45deg) translateY(-300%) translateX(15%);
  }
}

/* mobile mode starts at 767px */

@media screen and (max-width: 767px) {
  .navbar {
    padding: calc(.5vw + .5rem);
  }
  .navbar-brand img {
    width: calc(9vw + 9rem);
  }
}
footer {
  background: #F5F3E4;
  width: 100vw;
  margin-top: calc(3vw + 3rem);
  padding: calc(1vw + 1rem) calc(1vw + 1rem) calc(.5vw + .5rem) calc(1vw + 1rem);
}

footer h2 {
  font-size: calc(.5vw + .5rem);
  line-height: calc(1vw + 1rem);
}

footer .home-link:hover {
  text-decoration: none;
}

footer .footer-link {
  color: #641924;
  font-size: calc(.5vw + .5rem);
}

footer img {
  width: calc(1vw + 1rem);
  margin-left: 2vw;
}

@media screen and (max-width: 767px) {
  footer .col-md-4 {
    display: none !important;
  }

  footer img {
    margin-left: 0;
  }
}


@media screen and (max-width: 400px) {
  footer .row {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
  }

}
.contact-info {
  display: flex;
  justify-content: space-between;
  padding: 0 5vw;
}

.contact-details h3 {
  font-size: 1.25vw;
  font-family: 'Montserrat', sans-serif;
  color: rgba(100, 25, 36, 0.8);
  margin-left: 1.5vw;
  font-weight: bold;
  margin-bottom: 1vw;
}

.contact-details p {
  color: #FDFBEC;
  font-size: 1vw;
  border-radius: 100px;
  padding: 1vw 1.5vw;
  background: linear-gradient(120deg, rgba(189,36,15,1) 0%, rgba(244,137,123,1) 100%);
}

.contact-details p span:first-child {
  margin-right: 1vw;
}

.contact-details:nth-child(2) p, .contact-details:last-child p {
  background: linear-gradient(120deg, rgba(161,13,31,1) 0%, rgba(192,53,70,1) 100%);
}

.contact-details:nth-child(3) p {
  background: linear-gradient(120deg, rgba(67,4,13,1) 0%, rgba(124,52,63,1) 100%);
}

@media screen and (max-width: 992px) {
  .contact-details h3 {
    font-size: calc(.5vw + .5rem);
  }

  .contact-details p {
    font-size: calc(.4vw + .4rem);
  }
}

@media screen and (max-width: 767px) {
    .contact-info {
      flex-wrap: wrap;
    }

    .contact-details {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-right: calc(1vw + 1rem);
    }

    .contact-details h3 {
      font-size: calc(.75vw + .75rem);
      margin-left: calc(.5vw + .5rem);
    }

    .contact-details p {
      font-size: calc(.5vw + .5rem);
      padding: calc(.5vw + .5rem);
    }
}

@media screen and (max-width: 700px) {
  .contact-details {
    margin-right: 0 !important;
  }

  .contact-details h3 {
    margin-bottom: calc(.5vw + .5rem);
    font-size: 2.5vw;
  }
  .contact-details p {
    font-size: 2.5vw;
  }
}
/* landing page */
.landing {
  margin-top: calc(5vh + 5rem);
  padding: 0 calc(3vw + 3rem);
}

hr {
  opacity: 0;
}

.landing .btn {
  background: #C03546;
  color: white;
}

.buttons {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.buttons button {
  background: none;
  border: none;
  margin: 0 calc(.25vw + .25rem);
  color: #C03546 !important;
  outline: none;
  font-size: calc(.5vw + .5rem);
}

.slider {
  display: flex;
  align-items: center;
}

.slider i {
  cursor: pointer;
  color: #C03546;
  font-size: calc(1vw + 1rem);
  margin: calc(.25vw + .25rem);
}

.slider-container {
  width: 100%;
}

.slider-container .images {
  position: relative;
  width: 100%;
}

.slider-container .images img {
  transition: opacity .5s ease;
  width: 100%;
}

.slider-container .images img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.slider-container .images img:nth-child(3) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.slider-container .images img:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.slider-container .images img:nth-child(5) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.slider-container .images img:nth-child(6) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.slider-container .images img:nth-child(7) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.slider-container .images img:nth-child(8) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.slider-container .images img:last-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.text h1 {
  font-size: calc(1vw + 1rem);
  line-height: calc(1.75vw + 1.75rem);
  width: 100%;
  color: #641924;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.text p {
  width: 100%;
  color: #641924;
  font-size: calc(.75vw + .75rem);
  line-height: calc(1.5vw + 1.5rem);
  margin: calc(.75vw + .75rem) 0;
}

.text button.btn {
  font-size: calc(.5vw + .5rem);
  padding: calc(.25vw + .25rem) calc(.75vw + .75rem);
}

/* values section */
.values {
  justify-content: space-around;
  margin-top: calc(7vw + 7rem);
}

.values h2 {
  font-size: calc(.75vw + .75rem);
  font-weight: bold;
  color: white;
  font-family: 'Montserrat', sans-serif;
  margin-top: calc(1vw + 1rem);
  margin-left: calc(.75vw + .75rem);
}

.values div {
  width: calc(9vw + 9rem);
  height: calc(8vw + 8rem);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.value1 {
  background: url(/static/media/bible.d0e7fa69.jpg);
}

.value2 {
  background: url(/static/media/child.e616c1da.jpg);
}

.value3 {
  background: url(/static/media/children.3247b61e.jpg);
}

.value4 {
  background: url(/static/media/materials.0bb9f7cc.jpg);
}

/* learning section */
.learning {
  padding: 0 calc(3vw + 3rem);
}

.mission {
  padding: 0 calc(3vw + 3rem);
}

/* partners section */
.partners {
  margin: calc(3vw + 3rem) 0;
  padding: 0 calc(3vw + 3rem);
}

.partners h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #641924;
  font-size: calc(1vw + 1rem);
  margin-left: 2vw;
  margin-bottom: calc(1vw + 1rem);
}

.partners .container-fluid {
  background: #7C343F;
  border-radius: 2vw;
  padding: 2vw 5vw;
}

.partners img {
  width: 100%;
  margin: 0 auto;
  padding: 1vw;
}


/* media queries */

@media screen and (max-width: 1300px) {
  .values div {
    width: calc(8vw + 8rem);
    height: calc(8vw + 8rem);
  }
}


@media screen and (max-width: 992px) {
  .text h1 {
    font-size: calc(1vw + 1rem);
  }

  .text p {
    font-size: calc(.5vw + .5rem);
    line-height: calc(1vw + 1rem);
  }

  .values div {
    width: calc(7vw + 7rem);
    height: calc(6vw + 6rem);
  }

  .learning {
    flex-direction: column;
  }

  .learning div:first-child {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .landing.container-fluid {
    padding: calc(.75vw + .75rem) !important;
  }

  .learning.container-fluid {
    padding: calc(1.5vw + 1.5rem);
  }

  .learning .text {
    text-align: left !important;
  }

  .learning .text p {
    margin: calc(1vw + 1rem) 0 !important;
  }

  .text {
    text-align: center;
    margin-bottom: calc(2vw + 2rem);
  }

  .text p {
    font-size: calc(.75vw + .75rem);
    line-height: calc(1.5vw + 1.5rem);
    width: 95%;
    margin: calc(1vw + 1rem) auto;
  }

  .text button.btn {
    font-size: calc(.75vw + .75rem);
  }

  .slider .buttons {
    display: none;
  }

  .values {
    flex-wrap: wrap;
  }

  .values div {
    width: 40vw;
    height: 40vw;
  }

  .values h2 {
    font-size: 3vw;
  }

  .value2 {
    margin-top: 15vw;
  }

  .value4 {
    margin-top: 10vw;
  }

  .partners {
    padding: 0 2vw;
  }

  .partners img {
    padding: 0;
    width: 100%;
  }
}
.levels {
  padding: 0 calc(3vw + 3rem);
  margin-top: calc(1.5vw + 1.5rem);
}

.levels .col-md-6 {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  color: #FDFBEC !important;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.levels-buttons {
  flex: 1 1;
  display: flex;
  width: 100%;
}

.levels-text {
  background: #C03546;
  flex: 5 1;
}

.levels-buttons button {
  color: #FDFBEC;
  font-size: 1vw;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  background: #A02C39;
  border: none;
}

.levels-buttons button:first-child {
  border-radius: 1vw 0px 0px 0px;
}

.levels-buttons button:last-child {
  border-radius: 0px 1vw 0px 0vw;
}

.levels-text p {
  font-size: 1vw;
  padding: 2vw;
  line-height: 2.5vw;
}

.levels-btn-active {
  background: #C03546 !important;
}

@media screen and (max-width: 767px) {
  .levels {
    padding: 0;
  }
  .levels img {
    display: none;
  }
  .levels .col-md-6 {
    flex-direction: column;
    border-radius: .5rem;
  }
  .levels-text p {
    font-size: calc(.75vw + .75rem);
    padding: calc(1vw + 1rem);
    line-height: calc(1.75vw + 1.75rem);
  }
  .levels-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  .levels-buttons button {
    border: none !important;
    width: 100%;
    font-size: calc(.5vw + .5rem);
    padding: calc(.5vw + .5rem) 0 !important;
  }
  .levels-buttons button:first-child {
    border-radius: .5rem 0px 0px 0px;
  }
  .levels-buttons button:last-child {
    border-radius: 0px .5rem 0px 0px;
  }
}

@media screen and (max-width: 400px) {
  .levels-buttons button {
    font-size: calc(.5vw + .5rem);
  }
}
.faq.container-fluid {
  margin-top: calc(3vw + 3rem);
  padding: 0 0 0 calc(3vw + 3rem);
}

.col-md-10 {
  border-radius: 1vw 1vw 0 0;
}

.col-md-10 h1 {
  margin: 0 0 calc(1vw + 1rem) calc(1vw + 1rem);
  font-size: calc(1vw + 1rem);
  font-weight: bold;
  color: #641924;
  font-family: 'Montserrat', sans-serif;
}

.col-md-10 .container-fluid {
  padding: calc(1vw + 1rem);
  background: rgba(244, 137, 123, .8);
  border-radius: 1vw 1vw 0 0;
}

.question p.d-flex {
  color: #641924;
  margin: calc(1vw + 1rem) 0;
  font-size: calc(.6vw + .6rem);
  background: none;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  border: none;
  width: 100%;
  font-weight: bold;
}

.question button:focus {
  outline: none;
}

.question img {
  display: none !important;
}

.question .answer {
  overflow-y: hidden;
  max-height: 0;
  transition: max-height .5s ease;
  color: #641924;
  font-size: calc(.5vw + .5rem);
  line-height: calc(1vw + 1rem);
}

.question .answer-active {
  max-height: 50rem;
}

.col-md-2 {
  align-self: flex-end;
}

@media screen and (max-width: 992px) {
  .faq.container-fluid {
    padding: 0 2vw;
  }
}

@media screen and (max-width: 767px) {
  .col-md-10 .container-fluid {
    background: none;
    padding: 0 5vw !important;
  }
  .question p.d-flex {
    cursor: pointer;
    font-size: calc(.75vw + .75rem);
    line-height: calc(1.5vw + 1.5rem);
  }
  .question p.d-flex img {
    width: calc(1vw + 1rem);
    display: block !important;
  }
  .question .answer-active {
    max-height: 15rem !important;
  }
  .faq img {
    display: none;
  }
  .question .answer {
    font-size: calc(.75vw + .75rem) !important;
    line-height: calc(1.75vw + 1.75rem) !important;
  }
}
#about .landing p {
  font-size: calc(.5vw + .5rem);
  line-height: calc(1.25vw + 1.25rem);
  text-align: left;
}

#about .landing .col-lg-4 img {
  width: 100%;
}

.advantages {
  background: #f5f3e4;
  padding: calc(1vw + 1rem) 0;
  margin: calc(5vw + 5rem) 0;
}

.advantages h1 {
  font-size: calc(1vw + 1rem);
  margin-bottom: calc(1.5vw + 1.5rem);
  font-family: 'Montserrat', sans-serif;
  color: #641924;
}

.advantages img {
  width: 75%;
  margin: auto;
}

.advantages p {
  color: #641924;
  font-weight: bold;
  font-size: calc(.5vw + .5rem);
  line-height: calc(1vw + 1rem);
  margin-bottom: calc(1vw + 1rem);
}

.advantages i {
  font-size: calc(1vw + 1rem);
  margin-right: calc(.5vw + .5rem);
}

.centres {
  margin-bottom: calc(5vw + 5rem);
  padding: 0 calc(2vw + 2rem);
}

.centres img {
  padding: calc(1vw + 1rem);
}

.centres h1 {
  font-size: calc(1vw + 1rem);
  margin-bottom: calc(2vw + 2rem);
  font-family: 'Montserrat', sans-serif;
  color: #641924;
  font-weight: bold;
}

.centre h2 {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  color: #641924;
  font-size: calc(.5vw + .5rem);
}

.centre p {
  font-size: calc(.5vw + .5rem);
  color: #641924;
}

@media screen and (max-width: 992px) {
  #about .landing .col-lg-4 img {
    width: 100%;
    padding: 10vw;
  }
  .advantages .col-lg-3 {
    margin: 0 !important;
  }
  .advantages p {
    margin: 1rem auto !important;
    width: 95%;
    text-align: left;
    font-size: calc(.65vw + .65rem);
    line-height: calc(1.3vw + 1.3rem);
  }
  .advantages .col-lg-2 img {
    width: 100%;
    padding: 0 25vw;
  }
}

@media screen and (max-width: 767px) {
  #about .landing p {
    font-size: calc(.75vw + .75rem);
    line-height: calc(1.75vw + 1.75rem);
  }
  .centres {
    text-align: center;
  }
  .centre {
    margin: calc(1vw + 1rem) 0;
  }
  .centre p {
    font-size: calc(.75vw + .75rem);
  }
}
#curriculum .landing .text p {
  font-size: calc(.5vw + .5rem);
  line-height: calc(1.25vw + 1.25rem);
  text-align: left;
}

.curriculum-cards {
  padding: calc(1vw + 1rem) 0;
  margin: calc(4vw + 4rem) 0;
  background: #F5F3E4;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media screen and (max-width: 767px) {
  #curriculum .landing img {
    padding: 0 calc(2vw + 2rem);
  }

  #curriculum .landing p {
    font-size: calc(.75vw + .75rem) !important;
    line-height: calc(1.75vw + 1.75rem) !important;
  }

  .curriculum-cards {
    align-items: baseline !important;
  }

  .curriculum-cards p {
    font-size: calc(.75vw + .75rem) !important;
    line-height: calc(1.75vw + 1.75rem) !important;
  }
}
.curriculum-card {
  padding: calc(1vw + 1rem);
  background: #FDFBEC;
  border-top: .75vw solid #A10D1F;
  border-radius: .75vw .75vw 0 0;
  display: flex;
  width: 25vw;
  align-items: flex-start;
  margin: calc(1vw + 1rem) 0;
}

.curriculum-card img {
  width: 2vw;
  margin-right: 1vw;
}

.curriculum-card h1 {
  font-size: 1.25vw;
  line-height: 2vw;
}

.curriculum-card p {
  font-size: 1vw;
  line-height: 2vw;
}

@media screen and (max-width: 992px) {
  .curriculum-card {
    width: 40vw;
  }
}

@media screen and (max-width: 767px) {
  .curriculum-card {
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .curriculum-card img {
    width: 4vw;
    margin-right: 2vw;
  }

  .curriculum-card h1 {
    margin-left: calc(.5vw + .5rem);
    text-align: left;
    font-size: calc(.75vw + .75rem);
  }

  .curriculum-card p {
    text-align: left;
    font-size: calc(.5vw + .5rem);
    line-height: calc(1.25vw + 1.25rem);
  }
}
#admissions .landing p {
  font-size: calc(.5vw + .5rem);
  text-align: left;
}

.admissions-info {
  background: #F5F3E4;
  color: #641924 !important;
  padding: 5vw;
  margin: calc(3vw + 3rem) 0;
}

.admissions-info img {
  width: 100%;
  padding: 5vw;
}

.admissions-text {
  background: rgba(244, 137, 123, 0.8);
  border-radius: 1vw;
  padding: 0 !important;
}

.admissions-text h1 {
  padding: calc(1vw + 1rem);
  font-size: calc(1vw + 1rem);
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  border-bottom: 3px solid #F5F3E4;
}

.admissions-text div {
  padding: calc(1.5vw + 1.5rem);
}

.admissions-text p {
  font-size: calc(.5vw + .5rem);
  line-height: calc(1.25vw + 1.25rem);
}

.admissions-text h3 {
  line-height: calc(1vw + 1rem);
  font-size: calc(.5vw + .5rem);
  font-weight: bold;
  margin-top: calc(1vw + 1rem);
}

@media screen and (max-width: 1200px) {
  .admissions-info img {
    padding: 1vw;
  }
}

@media screen and (max-width: 767px) {
  #admissions .landing p {
    font-size: calc(.75vw + .75rem);
    line-height: calc(1.75vw + 1.75rem);
  }

  .admissions-info {
    padding: 10vw;
  }

  .admissions-info img {
    padding: 10vw;
  }

  .admissions-text div {
    padding: calc(1vw + 1rem);
  }

  .admissions-text h3 {
    font-size: calc(.75vw + .75rem);
    line-height: calc(1.75vw + 1.75rem);
  }

  .admissions-text p {
    font-size: calc(.75vw + .75rem);
    line-height: calc(1.75vw + 1.75rem);
  }
}
#contact .container-fluid {
  background: #f5f3e4;
  padding: 4vw;
  margin-top: calc(5vh + 5rem);
  margin-bottom: calc(5vh + 5rem);
  color: #641924;
}

#contact form {
  padding: 3vw;
  width: 100%;
  background: #F4897B;
  border-radius: 7px;
  border-left: 10px solid #641924;
}

#contact form h1 {
  font-size: calc(1vw + 1rem);
  font-weight: bold;
  margin-bottom: calc(1vw + 1rem);
  font-family: 'Montserrat', sans-serif;
}

#contact form .btn {
  background: #641924;
  border-radius: 7px;
  color: #FDFBEC;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(.5vw + .5rem);
  padding: calc(.25vw + .25rem) calc(1vw + 1rem);
}

#contact form .form-group {
  margin-top: calc(1vw + 1rem);
}

#contact form label {
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(.5vw + .5rem);
}

#contact form input, textarea {
  width: 100%;
  background: none;
  border: 3px solid #641924;
  border-radius: 5px;
  resize: vertical;
  padding: .5vw;
}

@media screen and (max-width: 767px) {
  #contact form {
    padding: calc(1vw + 1rem);
    border-left: 0;
  }

  .map {
    margin-top: calc(1vw + 1rem);
  }
}
