@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html, body {
  width: 100% !important;
  overflow-x: hidden !important;
  background: #FDFBEC;
}

.custom-control-input:focus~.custom-control-label::before {
  border-color: #AD4857 !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #AD4857 !important;
  background-color: #AD4857 !important;
}

.custom-control-input:active~.custom-control-label::before {
  background-color: #AD4857 !important;
  border-color: #AD4857 !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #AD4857 !important;
}

body section button.btn:focus {
  box-shadow: none;
}